<template>
  <div class="programa">
    <div class="title_wrap">
      <span class="title">{{title}}</span>
      <span :id="`popover-target${showCode}`" class="how" v-if="showCode">如何出现在这里？</span>
      <b-popover :target="`popover-target${showCode}`" triggers="hover" placement="top">
        <div class="text-center">
            添加工作人员微信<br>
            申请首页推荐位
            <br>
            <img :src="wechat_code" style="width:120px;height:120px" alt />
        </div>
      </b-popover>
      <a class="float-right look_more" v-if="lookMore" :href="moreLink" target="_blank">查看更多 ></a>
    </div>
    <b-row class="ml-0 mr-0 content_wrap">
        <a class="col item_wrap"
            v-for="(item,index) in data"
            :key="index" v-if="index<2"
            :href="item.link"
            target="_blank"
            :title="`${item.title},51赞助_校园赞助平台,${title}`"
        >
            <div class="img_wrap">
                <img :src="item.img" :alt='`${item.title},${seotitle}`' :title="item.title">
            </div>
            <p class="title">{{item.title}}</p>
            <p class="bottom" v-if="!showTime">
                <span class="float-left d-none d-lg-inline-block time">{{item.start_time}}至{{item.end_time}}</span>
                <span class="float-right area">{{item.province?item.province:'全国'}}</span>
            </p>
            <div class="company" :class="{'newCompany': showTime}" v-if="showCompany">{{item.company_name}}</div>
        </a>
        <div class="w-100 d-lg-none"></div>
        <a class="col item_wrap"
            v-for="(item,index) in data"
            :key="index"
            v-if="index>=2"
            :href="item.link"
            target="_blank"
            :title="`${item.title},51赞助_校园赞助平台,${title}`"
        >
            <div class="img_wrap">
                <img :src="item.img" :alt='`${item.title},${seotitle}`' :title="item.title">
            </div>
            <p class="title">{{item.title}}</p>
            <p class="bottom" v-if="!showTime">
                <span class="float-left d-none d-lg-inline-block time">{{item.start_time}}至{{item.end_time}}</span>
                <span class="float-right area">{{item.province?item.province:'全国'}}</span>
            </p>
            <div class="company" :class="{'newCompany': showTime}" v-if="showCompany">{{item.company_name}}</div>
        </a>
    </b-row>
  </div>
</template>

<script>
export default {
    name: "programa",
    props:[
        "title",//标题
        "showCode",//是否展示二维码
        "lookMore",//查看更多
        "moreLink",//查看更多跳转链接
        "showTime",//展示时间地址
        "showCompany",//展示公司
        "data",//数据
        'seotitle'//用于seo
    ],
    data() {
        return {
            wechat_code: "https://img.xiaoguokeji.com/51wechatcode1.png"
        };
    },
    created() {
    },
    methods:{
        /**
         * 外跳链接
         */
        toLink(link){
            window.open(link)
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/vars.scss";
.programa {
    margin-top: 40px;
    .title_wrap {
        width: 100%;
        height: 30px;
        .title {
            color: $baseFontColor;
            font-size: 20px;
            font-weight: bold;
        }
        .how {
            cursor: pointer;
            color: #999999;
            font-size: $baseFontSize;
            margin-left: 12px;
        }
        .look_more {
            line-height: 30px;
            font-size: $baseFontSize;
            color: $baseFontColor;
        }
        @media (max-width:768px){
            .title{
                font-size: 18px;
            }
            .how{
                font-size: 10px;
                margin:0;
            }
        }
    }
    .content_wrap{
        margin-top: 20px;
        .item_wrap{
            width: 23.5%;
            padding: 0;
            margin-right: 2%;
            background: #fff;
            border: 1px solid #EEEEEE;
            cursor: pointer;
            &:nth-child(5){
                margin-right: 0;
            }
            .img_wrap{
                width: 100%;
                height: 135px;
                overflow: hidden;
                display: flex;
                align-items: center;
                // background: #eee;
                background: #fff;
                img{
                    width: 100%;
                    &:hover{
                        opacity: .8;
                    }
                }
            }
            .title{
                height: 54px;
                padding: 15px 14px 0px;
                margin-bottom: 5px;
                font-size: $baseFontSize;
                color: $baseFontColor;
                &:hover{
                    color:#f73636;
                }
            }
            .bottom{
                height: 28px;
                margin: 0 14px;
                span{
                    font-size: 12px;
                    color:#999999;
                }
            }
            .company{
                height: 38px;
                padding: 0 14px;
                line-height: 38px;
                border-top: 1px solid #EEE;
                font-size: $baseFontSize;
                color: $baseFontColor;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .newCompany {
                border-top: none;
                font-size: 12px;
                color: #999999;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .programa{
        .content_wrap{
            .item_wrap{
                margin: 1.5% !important;
                .title{
                    height: 27px;
                    padding: 5px 5px 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .bottom{
                    height: 20px;
                    margin: 0 5px;
                }
                .company{
                    padding: 0 5px;
                }
            }
        }
    }
}
</style>
