<template>
    <main class="home">
        <b-container fluid>
            <b-carousel
                class="my_banner"
                controls
                indicators
                >
                <b-carousel-slide  v-for="(item,index) in banner" :key="index">
                    <div class="d-block w-100 banner_wrap " @click="toLink(item.url)" slot="img" :style="`text-align:center`">
                        <img :src="item.img" :alt="item.name" :title="item.name" :style="{'cursor': item.url?'pointer':'default'}">
                    </div>
                </b-carousel-slide>
            </b-carousel>
            <b-row class="dataShow">
                <b-col md='9' class="data_text" xs="12">
                    <b-row>
                        <b class="d-none d-lg-inline-block">数据</b>
                        <b-col ><span> {{data.company_total}} </span><br class="d-lg-none"> 企业入驻</b-col>
                        <b-col ><span> {{data.personal_total}} </span><br class="d-lg-none"> 社团入驻</b-col>
                        <b-col ><span> {{data.has_sponsor}} </span><br class="d-lg-none"> 成功赞助</b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-container class="content_main">
                <programa title="最新官方赞助" :show-code="1" :look-more="true"  more-link="/sponsor/businessList" :data="online" seotitle="校园赞助,社团赞助,找赞助"/>
<!--                <programa title="官方线下赞助" :data="downline" seotitle="校园赞助,社团赞助,找赞助"/>-->
                <programa title="优质校园活动" :show-code="2" :look-more="true" more-link="/sponsor/studentList" :show-company="false" :data="activity" seotitle="活动赞助,高校活动,校园活动"/>
                <programa title="精彩案例" :show-code="3" :show-time="true" :show-company="true" :data="precedentsList" seotitle="赞助商,企业赞助,找赞助,精彩案例"/>
                <!-- mobile显示 -->
                <div class="d-lg-none release" @click="release">
                    发布<br />赞助
                </div>
            </b-container>
            <!-- 合作伙伴 -->
            <div class="parter_wrap text-center">
                    <b class="title">合作伙伴</b>
                    <b-row class="mr-0 ml-0 partner clearfix">
                        <div
                            v-for="(item,index) in partnerArr"
                            :key='index'
                            class="partner_item"
                        >
                            <img :src="item.src" :alt="item.title">
                        </div>
                    </b-row>
                </div>
        </b-container>
    </main>
</template>
<script>
import {parterArr} from "@/assets/js/arrayData.js"
import { mapGetters } from 'vuex';
import server from '@/assets/utils/request.js';
import API from '@/assets/utils/API.js';
import programa from "@/components/programa"
export default {
    name:'home',
    components:{programa},
    head(){
        return{
            title:'51赞助 - 一站式校园活动赞助服务平台',
            meta:[
                { hid: 'keywords', name: 'keywords', content: '赞助,社团赞助,拉赞助,找赞助,活动赞助,赞助商,赞助费,校园活动,校园赞助'},
                { hid: 'description', name: 'description', content:'51赞助(51zanzhu.net) - 致力于为品牌提供低风险、高性价比的一站式校园活动赞助解决方案，覆盖全国1000多所高校，2100多个社团等优质校园资源，帮助品牌快速匹配最优活动，让企业进校园做活动更轻松、更专业、更高效。'},
            ]
        }
    },
    computed:{
        ...mapGetters([
            'type'
        ]),
        isLogin(){
            return this.$store.getters.token ? ((typeof this.$store.getters.token == 'function') ? false : true) : false;
        }
    },
    async asyncData () {
        //获取数据
        let [banner ,activity1,sponsor1,online1,downline1,data1] = await Promise.all([
                server.send('GET',API.$BANNER,{},{}),//活动列表
                server.send('GET',API.$ACIIVITY,{},{is_top:1,per_page:4}),//活动列表
                server.send('GET',API.$SPONSOR,{},{is_top:1,per_page:4}), //赞助列表
                server.send('GET',API.$SPONSOR,{},{online:1,official:1,per_page:4}),//线上列表
                server.send('GET',API.$SPONSOR,{},{online:0,official:1,per_page:4}),//线下列表
                server.send('GET',API.$HOME),//数据列表
            ])
        //数据整理，添加各自链接
        activity1.data.data.forEach(item=>item.link = `/sponsor/${item.id}/2`);
        sponsor1.data.data.forEach(item=>item.link = `/sponsor/${item.id}/1`);
        online1.data.data.forEach(item=>item.link = `/sponsor/${item.id}/1`);
        downline1.data.data.forEach(item=>item.link = `/sponsor/${item.id}/1`);
        return {
            activity:activity1.data.data,
            sponsor:sponsor1.data.data,
            online:online1.data.data,
            downline:downline1.data.data,
            data:data1.data.statistics,
            banner: banner.data
        }
    },
    data(){
        return {
            partnerArr: [
                {title: '比亚迪', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_byd.png'},
                {title: '高德地图', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_gd.png'},
                {title: '淘宝', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_tb.png'},
                {title: '京东', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_jd.png'},
                {title: 'watsons', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_watsons.png'},
                {title: '天猫国际', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_tm.png'},
                {title: '可口可乐', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_kl.png'},
                {title: '康师傅', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_ksf.png'},
                {title: '元气森林', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_sl.png'},
                {title: '奈雪', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_nx.png'},
                {title: '海底捞', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_hdl.png'},
                {title: 'HONOR', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_h.png'},
                {title: '联想', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_Lenovo.png'},
                {title: '苹果', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_iPhone.png'},
                {title: '阿里', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_aLi.png'},
                {title: '菜鸟', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_cn.png'},
                {title: 'BiLiBiLi', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_BiLiBiLi.png'},
                {title: '珂润', src: 'https://img.xiaoguokeji.com/xg_zanzhu_partner_kr.png'},
            ],
            precedentsList: [
                {
                    title: '助力B站链接高校社团，落地全国校园活动',
                    company_name: 'b站',
                    img: 'https://img.xiaoguokeji.com/xg_zanzhu_precedents_biLiBiLi.png',
                    link: 'https://mp.weixin.qq.com/s?__biz=MzI5NTc5NTc3NA==&mid=2247486299&idx=1&sn=311b4b9782a6db300c24848c37e7cb25&chksm=ec4f660adb38ef1c9ef2da16deff2e83388aab9f7c37703d9b430da2e7617cb6c4c07f33e72f#rd'
                },
                {
                    title: '高校百团大战掀起品牌校园声浪',
                    company_name: '金山文档',
                    img: 'https://img.xiaoguokeji.com/xg_zanzhu_precedents_jinShan.png',
                    link: 'https://mp.weixin.qq.com/s?__biz=MzI5NTc5NTc3NA==&mid=2247486299&idx=2&sn=9be8504090f12c54fb2063f03ca6ee74&chksm=ec4f660adb38ef1cd30b06db79c44c7a56fb13ded2927712bda8d53e7b82e380c24fa707e67f#rd'
                },
                {
                    title: '与Z时代共创内容，比亚迪玩出校园营销新高度',
                    company_name: '比亚迪',
                    img: 'https://img.xiaoguokeji.com/xg_zanzhu_precedents_BYD.png',
                    link: 'https://mp.weixin.qq.com/s?__biz=MzI5NTc5NTc3NA==&mid=2247486299&idx=3&sn=b124a6327330a15cb4aac50d39d6af31&chksm=ec4f660adb38ef1c6fe4102382791d800b7cb9f6d999a222fe5604d7d08f87c4d8a4a13185f9#rd'
                },
                {
                    title: '玩转校园，开启「来电新青年召集计划」',
                    company_name: '京小电',
                    img: 'https://img.xiaoguokeji.com/xg_zanzhu_precedents_jd.png',
                    link: 'https://mp.weixin.qq.com/s?__biz=MzI5NTc5NTc3NA==&mid=2247486299&idx=4&sn=f294266fb39c992846c007cdf9689b5f&chksm=ec4f660adb38ef1cb9ffdcd220f06963216461dc669fe136173ef747edd85474358805961416#rd'
                },
            ]
        }
    },
    methods:{
        toLink(url){
            url && window.open(url);
        },
        /**
         * 发布赞助
         */
        release(){
            if(this.type){
                this.$router.push('/sponsor/release');
            }else{
                if(this.isLogin){
                    this.$router.push('/account/identity');
                }else{
                    this.$router.push('/login/login');
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/vars.scss';
.home{
    .container{
        padding: 0;
        @media (max-width: 768px){
            padding: 0 15px;
        }
    }
    .my_banner{
        img {
            width: 1200px;
        }
        @media (min-width: 768px) {
            img{
                 height: 460px;
            }
        }
        @media (max-width: 768px) {
            img{
                width: 100%!important;
            }
        }
    }

    .dataShow{
        width: 100%;
        min-height: 60px;
        padding: 9px calc(50% - 600px);
        border-bottom: 1px solid #EEE;
        margin: 0;
        .data_name{
            font-size: 18px;
            line-height: 30px;
            color:$baseFontColor;
            &:after{
                width: 36px;
                height: 2px;
                background: $theme-color;
            }
        }
        .data_text{
            font-size: 16px;
            color: $baseFontColor;
            span{
                font-size: 30px;
                font-weight: bold;
            }
            b{
                height: 40px;
                margin-right: 100px;
                line-height: 45px;
                font-weight: normal;
                font-size: 24px;
                position: relative;
                &:after{
                    width:100%;
                    height: 3px;
                    background: #EE2E2E;
                    content: " ";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            .item{
               margin-right: 44px;
            }
        }
    }
    .content_main{
        padding-bottom: 40px;
        .release{
            width: 80px;
            height: 80px;
            position: fixed;
            bottom: 40px;
            right: 30px;
            color: #fff;
            background: #00ABFF;
            border-radius: 50%;
            font-size: 20px;
            text-align: center;
            padding-top: 16px;
            line-height: 24px;
            box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.15);
            z-index: 99;
        }
    }
    .parter_wrap{
        width: 100%;
        padding: 40px calc(50% - 600px) 44px;
        background: #F9F9FB;
        .title{
            display: block;
            font-size: 24px;
            color: $baseFontColor;
            margin: 0 auto 32px;
            @media ( max-width: 768px){
                margin-bottom: 30px;
            }
        }
        .partner{
            margin-top: 40px;
            .partner_item{
                width:184px;
                height: 70px;
                padding: 0;
                //border: 1px solid #ededed;
                float: left;
                margin: 0px 19px 20px 0;
                position: relative;
                background: #fff;
                &:nth-child(6n){
                    margin-right: 0;
                }
                img{
                    width: 100%;
                    height: 100%;
                    //background: #fff url('http://cdnsponsor.xiaoguokeji.com/parter_bg01.png') no-repeat;
                }
                .name{
                    width:100%;
                    height: 0;
                    color: #fff;
                    font-size: 12px;
                    line-height: 22px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    background: $theme-color;
                    transition:all .2s linear;
                    -webkit-transition:all .2s linear;
                }
                @media ( max-width: 768px){
                    width: 184px;
                    height: 70px;
                    margin: 40px calc(25% - 92px) 0 calc(25% - 92px) !important;
                }
            }
        }
    }
}
</style>


